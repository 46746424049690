@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/Pretendard-Bold.woff2") format("woff2"),
    url("../assets/fonts/Pretendard-Bold.woff2") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../assets/fonts/Pretendard-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Pretendard-SemiBold.woff2") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../assets/fonts/Pretendard-Medium.woff2") format("woff2"),
    url("../assets/fonts/Pretendard-Medium.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/Pretendard-Regular.woff2") format("woff2"),
    url("../assets/fonts/Pretendard-Regular.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../assets/fonts/Pretendard-Light.woff2") format("woff2"),
    url("../assets/fonts/Pretendard-Light.woff") format("woff");
}
